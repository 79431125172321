import { useEffect } from "react";
import WebSessionTrackingKeys from "constants/webSessionTracking";

const useTrackWebSession = (pageName: string) => {
	const { WEB_SESSION_START, WEB_SESSION_END } = WebSessionTrackingKeys;
	const webSessionTracker = () => {
		// If user moves away from the website, web session ends
		if (document.visibilityState === "hidden") {
			// Remove Web Session Start Previous
			sessionStorage.removeItem(WEB_SESSION_START);
			// Add Web Session End event to Session Storage.(Not a dependency for sending mixpanel event)
			sessionStorage.setItem(WEB_SESSION_END, Date().toString());
			import("../utils/Mixpanel").then((Mixpanel) => {
				Mixpanel.default.track(WEB_SESSION_END, {
					Source: pageName,
				});
			});
		}
		// If user returns, new web sessions starts
		if (document.visibilityState === "visible") {
			sessionStorage.setItem(WEB_SESSION_START, Date().toString());
			import("../utils/Mixpanel").then((Mixpanel) => {
				Mixpanel.default.track(WEB_SESSION_START, {
					Source: pageName,
				});
			});
		}
	};

	useEffect(() => {
		if (!sessionStorage.getItem(WEB_SESSION_START)) {
			// Dependency for sending event to mixpanel.
			sessionStorage.setItem(WEB_SESSION_START, Date().toString());
			import("../utils/Mixpanel").then((Mixpanel) => {
				Mixpanel.default.track(WEB_SESSION_START, {
					Source: pageName,
				});
			});
		}
		document.addEventListener("visibilitychange", webSessionTracker);
		return () => {
			document.removeEventListener("visibilitychange", webSessionTracker);
		};
	}, []);
};

export default useTrackWebSession;
