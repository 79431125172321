import TagManager from "react-gtm-module";

const actions = {
	addDataLayer: (data) => {
		const tagDataLayer = {
			dataLayer: {
				...data,
			},
			dataLayerName: "PageDataLayer",
		};
		TagManager.dataLayer(tagDataLayer);
	},
};

const TagManagerUtils = actions;

export default TagManagerUtils;
